$button: (
  font-family: var(--font-family),
  font-size: 14px,
  font-style: normal,
  font-weight: 700,
  line-height: 20px,
  letter-spacing: 0.8px,
);

$body-1: (
  font-family: var(--font-family),
  font-size: 16px,
  font-style: normal,
  font-weight: 400,
  line-height: 24px,
);

$body-2: (
  font-family: var(--font-family),
  font-size: 14px,
  font-style: normal,
  font-weight: 400,
  line-height: 20px,
);

$subtitle-1: (
  font-family: var(--font-family),
  font-size: 14px,
  font-style: normal,
  font-weight: 500,
  line-height: 20px,
  letter-spacing: 0.4px,
);

$subtitle-2: (
  font-family: var(--font-family),
  font-size: 12px,
  font-style: normal,
  font-weight: 700,
  line-height: 16px,
  letter-spacing: 0.4px,
);

$subtitle-3: (
  font-family: var(--font-family),
  font-size: 12px,
  font-weight: 600,
  line-height: 16px,
  font-style: normal,
  letter-spacing: 0.4px,
);

$caption: (
  font-family: var(--font-family),
  font-size: 12px,
  font-style: normal,
  font-weight: 400,
  line-height: 16px,
);

$overline: (
  font-family: var(--font-family),
  font-size: 10px,
  font-style: normal,
  font-weight: 500,
  line-height: 16px,
  letter-spacing: 0.4px,
  text-transform: uppercase,
);

$h1: (
  font-family: var(--font-family),
  font-size: 48px,
  font-style: normal,
  font-weight: 300,
  line-height: 72px,
  margin: 0px,
);

$h2: (
  font-family: var(--font-family),
  font-size: 34px,
  font-style: normal,
  font-weight: 300,
  line-height: 48px,
  margin: 0px,
);

$h3: (
  font-family: var(--font-family),
  font-size: 24px,
  font-style: normal,
  font-weight: 400,
  line-height: 32px,
  margin: 0px,
);

$h4: (
  font-family: var(--font-family),
  font-size: 20px,
  font-style: normal,
  font-weight: 500,
  line-height: 28px,
  letter-spacing: 0.4px,
  margin: 0px,
);

$h5: (
  font-family: var(--font-family),
  font-size: 16px,
  font-style: normal,
  font-weight: 700,
  line-height: 24px,
  letter-spacing: 0.8px,
  margin: 0px,
);

$p: (
  font-family: var(--font-family),
  font-size: 14px,
  font-weight: 400,
  line-height: 20px,
  font-style: normal,
);

$li: (
  font-family: var(--font-family),
  font-size: 14px,
  font-weight: 400,
  line-height: 20px,
  font-style: normal,
);

blockquote {
  background: rgba(10, 133, 255, 0.14);
  border-left: 4px solid #0066cc;
  border-radius: 4px;
  color: #666;
  margin: 20px 0;
  padding: 20px 20px;
}

@mixin typography-classes() {
  .button {
    @each $property, $value in $button {
      #{$property}: #{$value};
    }
  }
  .body-1 {
    @each $property, $value in $body-1 {
      #{$property}: #{$value};
    }
  }

  .body-2 {
    @each $property, $value in $body-2 {
      #{$property}: #{$value};
    }
  }

  .subtitle-1 {
    @each $property, $value in $subtitle-1 {
      #{$property}: #{$value};
    }
  }

  .subtitle-2 {
    @each $property, $value in $subtitle-2 {
      #{$property}: #{$value};
    }
  }

  .subtitle-3 {
    @each $property, $value in $subtitle-3 {
      #{$property}: #{$value};
    }
  }

  .caption {
    @each $property, $value in $caption {
      #{$property}: #{$value};
    }
  }

  .overline {
    @each $property, $value in $overline {
      #{$property}: #{$value};
    }
  }

  h1,
  .h1 {
    @each $property, $value in $h1 {
      #{$property}: #{$value};
    }
  }

  h2,
  .h2 {
    @each $property, $value in $h2 {
      #{$property}: #{$value};
    }
  }

  h3,
  .h3 {
    @each $property, $value in $h3 {
      #{$property}: #{$value};
    }
  }

  h4,
  .h4 {
    @each $property, $value in $h4 {
      #{$property}: #{$value};
    }
  }

  h5,
  .h5 {
    @each $property, $value in $h5 {
      #{$property}: #{$value};
    }
  }

  p,
  li {
    @each $property, $value in $p {
      #{$property}: #{$value};
    }
  }
}
