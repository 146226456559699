@use "../typography" as typography;
@use "../mixins" as mixins;

/**
 * AVAILABLE CSS VARIABLES
 *
 * --panelmenu-item-border-radius
 * --panelmenu-item-height
 * --panelmenu-item-margin
 * --panelmenu-item-padding
 * --panelmenu-item-border-radius
 * --panelmenu-item-spacing
 * --panelmenu-bg-color
 * --panelmenu-text-color
 * --panelmenu-icon-width
 * --panelmenu-icon-height
 * --panelmenu-font-family
 * --panelmenu-font-size
 * --panelmenu-font-weight
 * --panelmenu-font-style
 * --panelmenu-line-height
 */

@mixin menu-item-styles() {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: var(--panelmenu-item-spacing, 16px);
  box-sizing: border-box;
  border-radius: var(--panelmenu-item-border-radius, 8px);
  height: var(--panelmenu-item-height, 40px);
  margin: var(--panelmenu-item-margin, 0);
  padding: var(--panelmenu-item-padding, 8px);

  .p-menuitem-text,
  .p-menuitem-icon {
    color: var(--panelmenu-text-color, var(--g1));
  }

  .p-menuitem-text {
    @each $prop, $val in typography.$body-1 {
      #{$prop}: var(--panelmenu-#{$prop}, #{$val});
    }
  }

  &:not(.p-disabled) {
    &:hover {
      background: var(--panelmenu-highlight-bg-color, rgba(75, 76, 153, 0.2));
      color: var(
        --panelmenu-highlight-text-color,
        var(--theme-primary-light-text)
      );
    }

    &:focus-visible {
      //@include mixins.focused();
      background: var(--panelmenu-highlight-bg-color, rgba(75, 76, 153, 0.2));
      color: var(
        --panelmenu-highlight-text-color,
        var(--theme-primary-light-text)
      );
    }
  }
}

p-panelmenu {
  width: var(--panelmenu-width, 300px);

  &.p-panelmenu-icon-only {
    display: block;
    overflow: hidden;
    width: var(--panelmenu-icon-only-width, 72px);

    .p-panelmenu-header-content {
      width: 40px;
    }
    .p-panelmenu-header-action {
      justify-content: center;
    }

    .p-menuitem-text,
    .p-icon-wrapper {
      display: none;
    }
  }
}

.p-panelmenu {
  width: var(--panelmenu-width, 300px);
  background: var(--panelmenu-bg-color, var(--g6));
  padding: var(--panelmenu-padding, 16px);
  display: flex;
  flex-direction: column;
  gap: var(--panelmenu-item-spacing, 16px);

  .p-panelmenu-content .p-menuitem-text {
    padding-left: var(--panelmenu-item-padding, 16px);
  }

  .p-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
    color: var(--panelmenu-text-color, var(--g1));
  }

  .p-menuitem-icon {
    width: var(--panelmenu-icon-width, 20px);
    height: var(--panelmenu-icon-height, 20px);
  }

  .p-panelmenu-header-action {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 16px;
  }

  .p-panelmenu-header {
    outline: 0 none;

    &:focus-visible {
      border-radius: var(--panelmenu-item-border-radius, 8px);
      @include mixins.focused();
    }

    &.p-highlight {
      &:not(.p-disabled) {
        .p-icon-wrapper {
          color: var(--panelmenu-highlight-text-color, var(--g1));
        }

        .p-panelmenu-header-content {
          background: var(
            --panelmenu-highlight-bg-color,
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(255, 255, 255, 0.2) 100%
            )
          );
          color: var(
            --panelmenu-highlight-text-color,
            var(--theme-primary-light-text)
          );
        }
      }

      .p-icon-wrapper {
        transform: rotate(180deg);
      }
    }
  }

  .p-panelmenu-header-content,
  .p-menuitem-link {
    @include menu-item-styles();
  }

  .p-menuitem-link-active {
    background: var(--panelmenu-highlight-bg-color, rgba(75, 76, 153, 0.2));
    color: var(
      --panelmenu-highlight-text-color,
      var(--theme-primary-light-text)
    );

    .p-menuitem-text {
      font-weight: var(--panelmenu-font-weight, 700);
    }
  }

  .p-menuitem {
    --select-dropdown-item-bg-color: var(
      --panelmenu-bg-color,
      var(--theme-primary)
    );
    --select-dropdown-item-text-color: var(
      --panelmenu-text-color,
      var(--theme-primary-text)
    );
    --select-dropdown-item-focus-bg-color: var(
      --panelmenu-highlight-bg-color,
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.2) 100%
      )
    );
    --select-dropdown-item-focus-text-color: var(
      --panelmenu-highlight-text-color,
      var(--theme-primary-light-text)
    );

    &.p-focus {
			border-radius: 8px;
			outline-width: var(--form-control-focus-width, 2px);
      outline-style: var(--form-control-focus-style, solid);
      outline-color: var(--form-control-focus-color, var(--g6));
      outline-offset: calc(var(--form-control-focus-width, 2px) * -1);
      box-shadow: none;
		}
  }

  .p-panelmenu-content {
    .p-panelmenu-root-list {
      margin-top: var(--panelmenu-item-spacing, 16px);
      outline: 0 none;
      display: flex;
      flex-direction: column;
      gap: var(--panelmenu-item-spacing, 16px);
    }
  }
}
