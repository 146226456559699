/**
 * VARIABLES available
 * --toggle-button-width
 * --toggle-button-height
 * --toggle-button-border-color
 * --toggle-button-border-radius
 * --toggle-button-handle-width
 * --toggle-button-handle-height
 * --toggle-button-handle-left-padding
 * --toggle-button-handle-top-margin
 * --toggle-button-handle-border-radius
 * --toggle-button-off-background
 * --toggle-button-off-handle-color
 * --toggle-button-off-hover-background
 * --toggle-button-on-background
 * --toggle-button-on-handle-color
 * --toggle-button-on-hover-background
 */

.p-inputswitch {
  width: var(--toggle-button-width, 36px);
  height: var(--toggle-button-height, 20px);

  .p-inputswitch-slider {
    background: var(--toggle-button-off-background, var(--g2));
    transition: var(--form-control-element-transition);
    border-radius: var(--toggle-button-border-radius, 30px);
    border: 2px solid var(--toggle-button-border-color, var(--g2));

    &:before {
      background: var(--toggle-button-off-handle-color, var(--g6));
      width: var(--toggle-button-handle-width, 20px);
      height: var(--toggle-button-handle-height, 20px);
      left: var(--toggle-button-handle-left-padding, -2px);
      margin-top: var(--toggle-button-handle-top-margin, -10px);
      border-radius: var(--toggle-button-handle-border-radius, 50%);
      transition-duration: var(--transition-duration);
      border: 2px solid var(--toggle-button-border-color, var(--g2));
    }
  }

  &.p-focus {
    .p-inputswitch-slider {
      @include focused();
      outline-offset: 2px;
      outline-color: color-mix(in srgb, var(--g2) 40%, transparent);
    }
  }

  &:not(.p-disabled):hover {
    .p-inputswitch-slider {
      background: var(--toggle-button-off-hover-background, var(--g2));
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: var(--toggle-button-on-background, var(--theme-secondary));
      border: 2px solid var(--toggle-button-border-color, var(--theme-secondary));

      &:before {
        background: var(--toggle-button-on-handle-color, var(--g6));
        border: 2px solid var(--toggle-button-border-color, var(--theme-secondary));
        transform: translateX(var(--toggle-button-handle-width, 16px));
      }
    }

    &.p-focus {
      .p-inputswitch-slider {
        @include focused();
        outline-offset: 2px;
      }
    }

    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: var(--toggle-button-on-hover-background, var(--theme-secondary));
      }
    }

    &.p-disabled {
      .p-inputswitch-slider {
        background: var(--g3);
      }
    }
  }

  &.p-disabled {
    .p-inputswitch-slider {
      background: var(--g6);
      border: 2px solid var(--g3);

      &:before {
        border: 2px solid var(--g3);
      }
    }
  }
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  @include invalid-input();
}
