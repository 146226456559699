@use '../typography' as typography;
@use '../mixins' as mixins;

.p-treetable {
  table {
    border-collapse: collapse;
  }

  tr {
    background: var(--treetable-bg-color, var(--g6));
    color: var(--treetable-text-color, var(--g1));
    overflow: hidden;
  }

  th,
  td {
    padding: 0;
    border: 0;
  }

  .p-treetable-loading-overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .p-treetable-thead {
    .th {
      display: flex;
      align-items: center;
      flex: 1;
      margin: 0;
      margin-bottom: 8px;
      height: 56px;
      padding: 16px;
      text-align: left;
      border: 0;
      gap: 8px;
      border-top: 1px solid var(--table-border-color, var(--g4));
      border-bottom: 1px solid var(--table-border-color, var(--g4));
      @each $prop, $value in typography.$body-2 {
        #{$prop}: $value;
      }
    }
  }

  .p-treetable-tbody {
    .td {
      padding: 16px;
      height: 40px;
      display: flex;
      width: 100%;
      align-items: center;
      @each $prop, $value in typography.$body-2 {
        #{$prop}: $value;
      }
    }

    tr {
      .p-row-toggler,
      .p-row-editor-init,
      .p-row-editor-save,
      .p-row-editor-cancel {
        @include action-icon();
      }

      .p-row-editor-save {
        margin-right: var(--line-spacing);
      }

      &:hover {
        background: var(--treetable-bg-hover-color, var(--g5));
      }

      td:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      td:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.p-focused {
        @include mixins.focused-listitem();
        outline-width: 1px;
        border-radius: 4px;

        .p-checkbox-focused .p-focus {
          outline: none;
        }
      }

      &.p-highlight {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), var(--theme-secondary-light);
      }

      &.p-disabled {
        &:hover,
        &.p-highlight {
          background: var(--treetable-bg-color, var(--g6));
        }

        td {
          color: var(--g3);
        }
      }
    }
  }

  &.p-treetable-hoverable-rows {
    .p-treetable-tbody > tr:not(.p-highlight):hover {
      background: var(--treetable-bg-hover-color, var(--g5));
    }
  }

  .p-column-resizer-helper {
    background: $tableResizerHelperBg;
  }

  .p-treetable-scrollable-header,
  .p-treetable-scrollable-footer {
    background: var(--treetable-bg-color, var(--g6));
  }

  .p-treetable-loading-icon {
    font-size: $loadingIconFontSize;
  }

  &.p-treetable-gridlines {
    .p-treetable-header {
      border-width: 1px 1px 0 1px;
    }

    .p-treetable-footer {
      border-width: 0 1px 1px 1px;
    }

    .p-paginator-top {
      border-width: 0 1px 0 1px;
    }

    .p-paginator-bottom {
      border-width: 0 1px 1px 1px;
    }

    .p-treetable-thead {
      > tr {
        > th {
          border-width: 1px 0 1px 1px;

          &:last-child {
            border-width: 1px;
          }
        }
      }
    }

    .p-treetable-tbody {
      > tr {
        > td {
          border-width: 1px 0 0 1px;

          &:last-child {
            border-width: 1px 1px 0 1px;
          }
        }

        &:last-child {
          > td {
            border-width: 1px 0 1px 1px;

            &:last-child {
              border-width: 1px;
            }
          }
        }
      }
    }

    .p-treetable-tfoot {
      > tr {
        > td {
          border-width: 1px 0 1px 1px;

          &:last-child {
            border-width: 1px 1px 1px 1px;
          }
        }
      }
    }

    .p-treetable-thead + .p-treetable-tfoot {
      > tr {
        > td {
          border-width: 0 0 1px 1px;

          &:last-child {
            border-width: 0 1px 1px 1px;
          }
        }
      }
    }

    &:has(.p-treetable-thead):has(.p-treetable-tbody) .p-treetable-tbody {
      > tr {
        > td {
          border-width: 0 0 1px 1px;

          &:last-child {
            border-width: 0 1px 1px 1px;
          }
        }
      }
    }

    &:has(.p-treetable-tbody):has(.p-treetable-tfoot) .p-treetable-tbody {
      > tr {
        &:last-child {
          > td {
            border-width: 0 0 0 1px;

            &:last-child {
              border-width: 0 1px 0 1px;
            }
          }
        }
      }
    }
  }

  &.p-treetable-striped {
    .p-treetable-tbody {
      > tr:nth-child(even) {
        background: var(--treetable-striped-bg-color, #f9f9f9);

        &.p-highlight {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), var(--theme-secondary-light);
          color: var(--treetable-text-color, var(--g1));

          .p-row-toggler {
            color: var(--treetable-text-color, var(--g1));

            &:hover {
              color: var(--treetable-text-color, var(--g1));
            }
          }
        }
      }
    }
  }

  &.p-treetable-sm {
    .p-treetable-header {
      @include scaledPadding($tableHeaderPadding, $tableScaleSM);
    }

    .p-treetable-thead > tr > th {
      @include scaledPadding($tableHeaderCellPadding, $tableScaleSM);
    }

    .p-treetable-tbody > tr > td {
      @include scaledPadding($tableBodyCellPadding, $tableScaleSM);
    }

    .p-treetable-tfoot > tr > td {
      @include scaledPadding($tableFooterPadding, $tableScaleSM);
    }

    .p-treetable-footer {
      @include scaledPadding($tableFooterPadding, $tableScaleSM);
    }
  }

  &.p-treetable-lg {
    .p-treetable-header {
      @include scaledPadding($tableHeaderPadding, $tableScaleLG);
    }

    .p-treetable-thead > tr > th {
      @include scaledPadding($tableHeaderCellPadding, $tableScaleLG);
    }

    .p-treetable-tbody > tr > td {
      @include scaledPadding($tableBodyCellPadding, $tableScaleLG);
    }

    .p-treetable-tfoot > tr > td {
      @include scaledPadding($tableFooterPadding, $tableScaleLG);
    }

    .p-treetable-footer {
      @include scaledPadding($tableFooterPadding, $tableScaleLG);
    }
  }
}
