@use '../mixins' as mixins;

.p-dropdown {
  box-sizing: border-box;
  font-size: var(--form-control-font-size, 16px);
  color: var(--form-control-text-color, var(--g1));
  background: var(--form-control-bg-color, var(--g6));
  border-color: var(--form-control-border-color, var(--g3));
  border-width: var(--form-control-border-width, 1px);
  border-style: var(--form-control-border-style, solid);
  transition: var(--form-control-element-transition);
  border-radius: var(--form-control-border-radius, 8px);
  height: var(--form-control-height, 60px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;

  input {
    cursor: text;
  }

  .p-inputtext {
    outline: 0 !important;
    padding: 16px;
  }

  &:not(.p-disabled) {
    &:hover,
    &.p-focus {
      @include mixins.focused-input();
    }
  }

  &.p-disabled {
    background-color: var(--g5) !important;
    cursor: not-allowed;

    &:hover,
    &.p-focus {
      outline-color: transparent !important;
      border-color: var(--form-control-border-color, var(--g3)) !important;
    }

    .p-dropdown-label {
      cursor: not-allowed;
    }
  }

  &.p-dropdown-clearable {
    .p-dropdown-label {
      padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }
  }

  .p-dropdown-label {
    background: transparent;
    border: 0 none;
    display: flex;
    align-items: center;

    &:focus,
    &:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }
  }

  .p-dropdown-trigger {
    background: transparent;
    color: var(--select-caret-color, var(--form-control-text-color, var(--g1)));
    width: var(--form-control-addon-min-width, 40px);
    border-top-right-radius: var(--select-border-radius, 8px);
    border-bottom-right-radius: var(--select-border-radius, 8px);
  }

  .p-dropdown-clear-icon {
    color: var(--select-icon-color, var(--form-control-text-color, var(--g1)));
    right: var(--form-control-addon-min-width, 40px);
  }

  &.p-invalid.p-component {
    @include invalid-input();
  }
}

.ng-touched {
  &.ng-invalid {
    &.p-element {
      .p-dropdown {
        @include mixins.invalid-input();
      }
    }
  }
}

.p-dropdown-panel {
  background: var(--menu-bg-color, var(--g6));
  color: var(--menu-text-color, var(--g1));
  border-width: var(--menu-border-width, 1px);
  border-style: var(--menu-border-style, solid);
  border-color: var(--menu-border-color, var(--g3));
  border-radius: var(--menu-border-radius, 8px);
  box-shadow: 0px 4px 8px 0px rgba(26, 26, 26, 0.24);
  overflow: hidden;

  .p-dropdown-header {
    padding: var(--menu-header-padding, 8px 16px);
    border-bottom: var(--menu-header-border-bottom, 1px solid var(--g3));
    color: var(--menu-text-color, var(--g1));
    background: transparent;
    margin: var(--menu-header-margin, 0);

    .p-dropdown-filter {
      padding-right: var(--form-control-padding-for-icon, 44px);
      margin-right: calc(-1 * var(--form-control-padding-for-icon, 44px));
    }

    .p-dropdown-filter-icon {
      right: var(--form-control-padding-for-icon, 44px);
      color: var(--select-icon-color, var(--form-control-text-color, var(--g1)));
    }
  }

  .p-dropdown-items {
    padding: var(--menu-items-padding, 0);

    .p-dropdown-item {
      box-sizing: border-box;
      height: var(--menu-height, 48px);
      margin: var(--menu-item-margin, 0);
      padding: var(--menu-item-padding, 16px);
      border-color: var(--menu-item-border-color, transparent);
      border-width: var(--menu-item-border-color, 0);
      border-style: var(--menu-item-border-color, solid);
      color: var(--menu-item-text-color, var(--g1));
      background: var(--menu-item-bg-color, var(--g6));
      transition: var(--form-control-element-transition);
      border-radius: var(--menu-item-border-radius, 0);
      font-size: 14px;

      &.p-highlight {
        color: var(--menu-item-selected-text-color, var(--theme-secondary-text));
        background: var(--menu-item-selected-bg-color, var(--theme-secondary));

        &:hover,
        &.p-focus {
          color: var(--menu-item-selected-hover-text-color, var(--theme-secondary-light-text));
          background: var(--menu-item-selected-hover-bg-color, var(--theme-secondary-light));
        }
      }

      &:not(.p-highlight):not(.p-disabled) {
        &.p-focus,
        &:hover {
          color: var(--menu-item-focus-text-color, var(--g1));
          background: var(--menu-item-focus-bg-color, var(--g5));
        }
      }
    }

    .p-dropdown-item-group {
      margin: $submenuHeaderMargin;
      padding: $submenuHeaderPadding;
      color: $submenuHeaderTextColor;
      background: $submenuHeaderBg;
      font-weight: $submenuHeaderFontWeight;
    }

    .p-dropdown-empty-message {
      padding: var(--menu-item-padding, 16px 16px);
      color: var(--menu-item-text-color, var(--g1));
      background: var(--menu-item-bg-color, var(--g6));
    }
  }
}

.p-input-filled {
  .p-dropdown {
    background: $inputFilledBg;

    &:not(.p-disabled):hover {
      background: var(--menu-bg-color, var(--g1));
    }

    &:not(.p-disabled).p-focus {
      background: var(--menu-bg-color, var(--g1));

      .p-inputtext {
        background-color: transparent;
      }
    }
  }
}

.p-float-label {
  position: relative;
}

.p-float-label > label {
  --left-position: 16px;
  position: absolute;
  top: 23px;
  left: var(--left-position);
  max-width: calc(100% - var(--left-position) * 2);
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.p-float-label.editable-dropdown {
  .p-inputwrapper-filled {
    ~ label {
      top: 12px;
      font-size: var(--form-control-floating-label-font-size, 12px);
      font-style: var(--form-control-floating-label-font-style, normal);
      font-weight: var(--form-control-floating-label-font-weight, 700);
      letter-spacing: var(--form-control-floating-label-letter-spacing, 0.4px);
    }

    .p-dropdown-label {
      padding-top: 20px;
      padding-bottom: 0;
    }
    .p-placeholder {
      opacity: 1;
    }
  }
}
