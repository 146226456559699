@use './assets/scss/typography' as typography;
@use './assets/scss/small-form' as small-forms;
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import '../node_modules/@accesso/ng-uds/dist/src/assets/styles/primeng-sass-theme-16.8.0/themes/viva/viva-light/variables';
@import '../node_modules/@accesso/ng-uds/dist/src/assets/styles/variables';
@import '../node_modules/@accesso/ng-uds/dist/src/assets/styles/app-theme';
@import '../node_modules/@accesso/ng-uds/dist/src/assets/styles/primeng-sass-theme-16.8.0/theme-base/components';

@include typography.typography-classes();

[hidden] {
  display: none !important;
}

@each $g in (1, 2, 3, 4, 5, 6) {
  .g#{$g} {
    color: var(--g#{$g});
  }
}

a,
a:visited {
  color: var(--theme-secondary);
}

@include small-forms.small-form();

//Overrides
@import './assets/scss/components/_panelmenu';
@import './assets/scss/components/_dropdown';
@import './assets/scss/components/_treetable';
@import './assets/scss/components/_inputSwitch';
@import './assets/scss/components/_radioButton';
