/**
 * VARIABLES available
 * --radio-button-width
 * --radio-button-height
 * --radio-button-border-color
 * --radio-button-background
 * --radio-button-text-color
 * --radio-button-border-radius
 * --radio-button-hover-border-color
 * --radio-button-icon-size
 * --radio-button-icon-active-color
 * --radio-button-active-border-color
 * --radio-button-active-background
 * --radio-button-active-hover-border-color
 * --radio-button-active-hover-background
 * --radio-button-active-hover-icon-color
 * --radio-button-label-font-size
 * --radio-button-label-font-family
 * --radio-button-label-font-style
 * --radio-button-label-font-weight
 * --radio-button-label-line-height
 */

p-radiobutton {
  display: flex;
  align-items: center;
}

.p-radiobutton {
  width: var(--radio-button-width, 20px);
  height: var(--radio-button-height, 20px);

  &.p-radiobutton-focused {
    &:not(.p-radiobutton-disabled) {
      @include focused();
      outline-offset: 2px;
      border-radius: var(--radio-button-border-radius, 50%);
    }
  }

  .p-radiobutton-box {
    border: 2px solid var(--radio-button-border-color, var(--g2));
    background: var(--radio-button-background, var(--g6));
    width: var(--radio-button-width, 20px);
    height: var(--radio-button-height, 20px);
    color: var(--radio-button-text-color, var(--text-color));
    border-radius: var(--radio-button-border-radius, 50%);
    transition: var(--form-control-element-transition);

    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: var(--radio-button-hover-border-color, var(--g2));
    }

    .p-radiobutton-icon {
      width: var(--radio-button-icon-size, 8px);
      height: var(--radio-button-icon-size, 8px);
      transition-duration: var(--transition-duration);
      background-color: var(--radio-button-icon-active-color, var(--g6));
    }

    &.p-highlight {
      border-color: var(--radio-button-active-border-color, var(--g2));
      background: var(--radio-button-active-background, var(--g2));

      // Negative offset to create interior dot
      outline-width: 4px;
      outline-style: solid;
      outline-color: var(--radio-button-background, var(--g6));
      outline-offset: -6px;

      &:not(.p-disabled):hover {
        border-color: var(--radio-button-active-hover-border-color, var(--g2));
        background: var(--radio-button-active-hover-background, var(--g2));
        color: var(--radio-button-active-hover-icon-color, var(--g6));
      }

      &.p-disabled {
        border-color: var(--g3);
        background: var(--g3);
      }
    }

    &.p-disabled {
      border-color: var(--g3);
      background: var(--g6);
    }
  }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  @include invalid-input();
}

.p-input-filled {
  .p-radiobutton {
    .p-radiobutton-box {
      background-color: var(--form-control-bg-color, var(--g6));

      &:not(.p-disabled):hover {
        background-color: var(--form-control-hover-bg-color, var(--g6));
      }

      &.p-highlight {
        background: var(--radio-button-active-background, var(--g2));

        &:not(.p-disabled):hover {
          background: var(--radio-button-active-hover-background, var(--g2));
        }
      }
    }
  }
}

.p-radiobutton-label {
  margin-left: 8px;
  font-size: var(--radio-button-label-font-size, 16px);
  font-family: var(--radio-button-label-font-family, var(--font-family));
  font-style: var(--radio-button-label-font-style, normal);
  font-weight: var(--radio-button-label-font-weight, 400);
  line-height: var(--radio-button-label-line-height, 24px);
}
